<template>
  <v-container id="dashboard" fluid tag="section">
    <v-flex xs12 sm12 md12>
      <material-card>
        <template v-slot:heading>
          <div class="display-2 font-weight-light" icon="fas fa-history">
            Historic
          </div>
        </template>
        <v-row row>
          <v-col md="2" xs="12"> Search/Busqueda </v-col>
          <v-col md="2" xs="12"> Company/Compania </v-col>
          <v-col md="2" xs="12"> Checkin Date/Fecha Checkin</v-col>
        </v-row>
        <v-row row>
          <v-col md="2" xs="12">
            <v-text-field
              solo
              class="right-input"
              v-model="form.search"
              maxlength="13"
              placeholder="Search order:"
              v-on:keyup.enter="find"
            ></v-text-field>
          </v-col>
          <v-col md="2" xs="12">
            <v-autocomplete
              solo
              :items="controls.companies"
              item-text="companyName"
              item-value="idCompany"
              v-model="form.idCompany"
            ></v-autocomplete>
          </v-col>
          <v-col md="2" xs="12">
            <date-range-picker
              solo
              ref="picker"
              :opens="controls.datepicker.opens"
              :minDate="controls.datepicker.minDate"
              :maxDate="controls.datepicker.maxDate"
              :singleDatePicker="controls.datepicker.singleDatePicker"
              :timePicker="controls.datepicker.timePicker"
              :showWeekNumbers="controls.datepicker.showWeekNumbers"
              :showDropdowns="controls.datepicker.showDropdowns"
              :autoApply="controls.datepicker.autoApply"
              v-model="controls.datepicker.dateRange"
              :ranges="controls.datepicker.show_ranges ? undefined : false"
              @update="updateValues"
              :linkedCalendars="controls.datepicker.linkedCalendars"
              :dateFormat="dateFormat"
            >
              <div slot="input" slot-scope="picker" style="min-width: 150px">
                <div
                  class="
                    v-input
                    theme--light
                    v-text-field
                    v-text-field--single-line
                    v-text-field--solo
                    v-text-field--is-booted
                    v-text-field--enclosed
                    v-select v-autocomplete
                  "
                >
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        {{ picker.startDate | date }} -
                        {{ picker.endDate | date }}
                      </div>
                      <div class="v-input__append-inner">
                        <div class="v-input__icon v-input__icon--append">
                          <v-icon right>mdi-calendar-range</v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template slot="calendar-icon">
                <icon>mdi-calendar</icon>
              </template>
            </date-range-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="blue" @click="find" dark>
              Find/Buscar
              <v-icon right>fas fa-search</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-layout row wrap>
          <v-flex md6 sm12>
            <material-card
              icon="mdi-check-all"
              title="Done Inbound/Entradas Hechas"
            >
              <v-card-text class="page-info dashboard-info">
                <v-data-table
                  :headers="inbound.done.headers"
                  :items="inbound.done.data"
                  :options.sync="inbound.done.options"
                  :server-items-length="inbound.done.totalItems"
                  :loading="inbound.done.isLoading"
                  class="elevation-1"
                >
                  <template v-slot:[`item.inOut`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.inOut"
                      @save="saveInboundInOut(item)"
                      large
                      persistent
                    >
                      <div class="div-status-dashboard">
                        {{ item.inOut }}
                      </div>
                      <template v-slot:input>
                        <integer-input
                          :min="0"
                          :max="1000"
                          :allow_negative="false"
                          v-model="item.inOut"
                        ></integer-input>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template
                    v-slot:[`item.actions`]="{ item }"
                    class="justify-center layout px-0"
                  >
                    <v-icon
                      small
                      class="mr-2"
                      @click="goInbound(item)"
                      title="Edit/Editar"
                      >mdi-more</v-icon
                    >
                  </template>

                  <template slot="pageText" slot-scope="props"
                    >Lines {{ props.pageStart }} - {{ props.pageStop }} de
                    {{ props.itemsLength }}</template
                  >
                </v-data-table>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="green"
                  @click="exportInbound"
                  dark
                  :disabled="inbound.done.isLoading"
                >
                  Export
                  <v-icon right>fas fa-download</v-icon>
                </v-btn>
              </v-card-actions>
            </material-card>
          </v-flex>
          <v-flex md6 sm12>
            <material-card
              icon="mdi-check-all"
              title="Done Outbound/Salidas Hechas"
            >
              <v-card-text class="page-info dashboard-info">
                <v-data-table
                  :headers="outbound.done.headers"
                  :items="outbound.done.data"
                  :options.sync="outbound.done.options"
                  :server-items-length="outbound.done.totalItems"
                  :loading="outbound.done.isLoading"
                  class="elevation-1"
                >
                  <template v-slot:[`item.inOut`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.inOut"
                      @save="saveOutboundInOut(item)"
                      large
                      persistent
                    >
                      <div class="div-status-dashboard">
                        {{ item.inOut }}
                      </div>
                      <template v-slot:input>
                        <integer-input
                          :min="0"
                          :max="1000"
                          :allow_negative="false"
                          v-model="item.inOut"
                        ></integer-input>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template
                    v-slot:[`item.actions`]="{ item }"
                    class="justify-center layout px-0"
                  >
                    <v-icon
                      small
                      class="mr-2"
                      @click="goOutbound(item)"
                      title="Edit/Editar"
                      >mdi-more</v-icon
                    >
                  </template>
                  <template slot="pageText" slot-scope="props"
                    >Lines {{ props.pageStart }} - {{ props.pageStop }} de
                    {{ props.itemsLength }}</template
                  >
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="green"
                  @click="exportOutbound"
                  dark
                  :disabled="outbound.done.isLoading"
                >
                  Export
                  <v-icon right>fas fa-download</v-icon>
                </v-btn>
              </v-card-actions>
            </material-card>
          </v-flex>
        </v-layout>
      </material-card>
    </v-flex>
  </v-container>
</template>

<script>
import moment from "moment";
import inbound from "../../services/api/inbound";
import outbound from "../../services/api/outbound";
import companies from "@/services/api/companies";

import { mapGetters } from "vuex";

export default {
  name: "driverInbound",
  filters: {
    date(value) {
      if (!value) return "";
      return moment(value).format("YYYY-MM-DD");
    },
  },
  components: {
    MaterialCard: () => import("@/components/base/MaterialCard"),
    IntegerInput: () => import("@/components/fields/IntegerInput"),
  },
  data() {
    return {
      form: {
        search: null,
        idCompany: null,
        date: {},
      },
      controls: {
        companies: [],
        searchForm: null,
        datepicker: {
          opens: "center",
          minDate: "2021-06-01",
          maxDate: moment().add(1, "days").format("YYYY-MM-DD"),
          dateRange: {
            startDate: moment().startOf("month").format("YYYY-MM-DD"),
            endDate: moment().add(1, "days").format("YYYY-MM-DD"),
          },
          show_ranges: true,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: false,
          showDropdowns: true,
          autoApply: false,
          showWeekNumbers: true,
          linkedCalendars: true,
        },
      },
      inbound: {
        done: {
          headers: [
            {
              text: "PO/Vendor",
              align: "left",
              sortable: true,
              value: "po",
            },
            {
              text: "Company",
              align: "left",
              sortable: true,
              value: "companyName",
            },
            {
              text: "Door",
              align: "center",
              sortable: false,
              value: "doorName",
            },
            {
              text: "CheckIn",
              align: "left",
              sortable: false,
              value: "checkInDate",
            },
            {
              text: "Diff",
              align: "end",
              sortable: false,
              value: "checkInDoorAssignment",
            },
            {
              text: "InOut",
              align: "left",
              sortable: false,
              value: "inOut",
            },
            {
              text: "Actions",
              align: "center",
              sortable: false,
              value: "actions",
            },
          ],
          data: [],
          pagination: {
            filters: "",
            bActivePaged: true,
            page: 1,
            itemsPerPage: 15,
            sortBy: "",
            sortDesc: false,
          },
          options: {},
          totalItems: 0,
          isLoading: false,
        },
      },
      outbound: {
        done: {
          headers: [
            {
              text: "PU/Customer",
              align: "left",
              sortable: true,
              value: "so",
            },
            {
              text: "Company",
              align: "left",
              sortable: true,
              value: "companyName",
            },
            {
              text: "Door",
              align: "center",
              sortable: false,
              value: "doorName",
            },
            {
              text: "CheckIn",
              align: "left",
              sortable: false,
              value: "checkInDate",
            },
            {
              text: "Diff",
              align: "end",
              sortable: false,
              value: "checkInDoorAssignment",
            },
            {
              text: "InOut",
              align: "left",
              sortable: false,
              value: "inOut",
            },
            {
              text: "Actions",
              align: "center",
              sortable: false,
              value: "actions",
            },
          ],
          data: [],
          pagination: {
            filters: "",
            bActivePaged: true,
            page: 1,
            itemsPerPage: 15,
            sortBy: "",
            sortDesc: false,
          },
          options: {},
          totalItems: 0,
          isLoading: false,
        },
      },
      doors: [],
    };
  },
  created() {
    this.getAllCompanies("");
    if (this.$route.query.filters) {
      const filters = JSON.parse(this.$route.query.filters);
      this.form = filters;
      this.controls.datepicker.dateRange.startDate = filters.date.startDate;
      this.controls.datepicker.dateRange.endDate = filters.date.endDate;
    } else if (
      this.getPrevious.name == "inbound" ||
      this.getPrevious.name == "outbound"
    ) {
      const filters = JSON.parse(localStorage.getItem("historic"));
      if (filters) {
        this.form = filters;
        this.controls.datepicker.dateRange.startDate = filters.date.startDate;
        this.controls.datepicker.dateRange.endDate = filters.date.endDate;
      }
    } else {
      this.updateValues(this.controls.datepicker.dateRange);
    }
  },
  beforeDestroy() {
    clearInterval(this.t);
  },
  watch: {
    "inbound.done.options": {
      handler() {
        this.getHistoricInbound();
      },
      deep: true,
    },
    "outbound.done.options": {
      handler() {
        this.getHistoricOutbound();
      },
      deep: true,
    },
  },
  methods: {
    async find() {
      this.fetchData(this.search);
      this.t = setInterval(() => {
        this.fetchData(this.search);
      }, 60 * 1000);
    },
    async getHistoricInbound() {
      this.inbound.done.isLoading = true;
      try {
        this.inbound.done.pagination.filters = this.form.search;
        this.inbound.done.pagination.idCompany = this.form.idCompany;
        this.inbound.done.pagination.startDate = this.form.date.startDate;
        this.inbound.done.pagination.endDate = this.form.date.endDate;
        this.inbound.done.pagination.bActivePaged = true;
        this.inbound.done.pagination.page = this.inbound.done.options.page;
        this.inbound.done.pagination.itemsPerPage =
          this.inbound.done.options.itemsPerPage;
        this.inbound.done.pagination.sortBy =
          this.inbound.done.options.sortBy[0];
        this.inbound.done.pagination.sortDesc =
          this.inbound.done.options.sortDesc[0];

        let OData = Object.assign({}, this.inbound.done.pagination);
        OData.qaFormat = JSON.stringify(this.inbound.done.pagination.qaFormat);

        const response = await inbound.historicPaged(OData);
        this.inbound.done.data = response.body.data;
        this.inbound.done.totalItems = response.body.totalItems;

        this.inbound.done.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async exportInbound() {
      this.inbound.done.isLoading = true;
      try {
        this.inbound.done.pagination.filters = this.form.search;
        this.inbound.done.pagination.idCompany = this.form.idCompany;
        this.inbound.done.pagination.startDate = this.form.date.startDate;
        this.inbound.done.pagination.endDate = this.form.date.endDate;
        this.inbound.done.pagination.bActivePaged = true;
        this.inbound.done.pagination.page = this.inbound.done.options.page;
        this.inbound.done.pagination.itemsPerPage =
          this.inbound.done.options.itemsPerPage;
        this.inbound.done.pagination.sortBy =
          this.inbound.done.options.sortBy[0];
        this.inbound.done.pagination.sortDesc =
          this.inbound.done.options.sortDesc[0];

        let OData = Object.assign({}, this.inbound.done.pagination);
        OData.qaFormat = JSON.stringify(this.inbound.done.pagination.qaFormat);

        const response = await inbound.historicExport(OData);
        const blob = new Blob([response.body]);
        const link = document.createElement("a");
        link.className = "file";
        link.href = window.URL.createObjectURL(blob);
        link.download = "HistoricInboud.xlsx";
        document.body.appendChild(link);
        link.click();

        this.inbound.done.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getHistoricOutbound() {
      this.outbound.done.isLoading = true;
      try {
        this.outbound.done.pagination.filters = this.form.search;
        this.outbound.done.pagination.idCompany = this.form.idCompany;
        this.outbound.done.pagination.startDate = this.form.date.startDate;
        this.outbound.done.pagination.endDate = this.form.date.endDate;
        this.outbound.done.pagination.bActivePaged = true;
        this.outbound.done.pagination.page = this.outbound.done.options.page;
        this.outbound.done.pagination.itemsPerPage =
          this.outbound.done.options.itemsPerPage;
        this.outbound.done.pagination.sortBy =
          this.outbound.done.options.sortBy[0];
        this.outbound.done.pagination.sortDesc =
          this.outbound.done.options.sortDesc[0];

        let OData = Object.assign({}, this.outbound.done.pagination);
        OData.qaFormat = JSON.stringify(this.outbound.done.pagination.qaFormat);

        const response = await outbound.historicPaged(OData);
        this.outbound.done.data = response.body.data;
        this.outbound.done.totalItems = response.body.totalItems;

        this.outbound.done.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async exportOutbound() {
      this.outbound.done.isLoading = true;
      try {
        this.outbound.done.pagination.filters = this.form.search;
        this.outbound.done.pagination.idCompany = this.form.idCompany;
        this.outbound.done.pagination.startDate = this.form.date.startDate;
        this.outbound.done.pagination.endDate = this.form.date.endDate;
        this.outbound.done.pagination.bActivePaged = true;
        this.outbound.done.pagination.page = this.outbound.done.options.page;
        this.outbound.done.pagination.itemsPerPage =
          this.outbound.done.options.itemsPerPage;
        this.outbound.done.pagination.sortBy =
          this.outbound.done.options.sortBy[0];
        this.outbound.done.pagination.sortDesc =
          this.outbound.done.options.sortDesc[0];

        let OData = Object.assign({}, this.outbound.done.pagination);
        OData.qaFormat = JSON.stringify(this.outbound.done.pagination.qaFormat);

        const response = await outbound.historicExport(OData);
        const blob = new Blob([response.body]);
        const link = document.createElement("a");
        link.className = "file";
        link.href = window.URL.createObjectURL(blob);
        link.download = "HistoricOutboud.xlsx";
        document.body.appendChild(link);
        link.click();

        this.outbound.done.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchData(search) {
      this.getHistoricInbound();
      this.getHistoricOutbound();
    },
    goInbound(item) {
      localStorage.setItem("historic", JSON.stringify(this.form));
      this.$router.push({
        name: "inbound",
        query: { filters: JSON.stringify(this.form) },
        params: {
          id: item.id,
        },
      });
    },
    goOutbound(item) {
      localStorage.setItem("historic", JSON.stringify(this.form));
      this.$router.push({
        name: "outbound",
        query: { filters: JSON.stringify(this.form) },
        params: {
          id: item.id,
        },
      });
    },
    checkInFormat(date) {
      if (date) return moment(date).format("MM/DD/YY HH:mm:ss");
      else return "";
    },
    async saveInboundInOut(item) {
      try {
        await inbound.saveDashboardInOut(item);
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    async saveOutboundInOut(item) {
      try {
        await outbound.saveDashboardInOut(item);
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    dateFormat(classes, date) {
      const dateToCompare = moment(date);
      let yesterday = moment().subtract(3, "day");
      //if (!classes.disabled) {
      //  classes.disabled = dateToCompare.isSame(yesterday, "day");
      //}
      return classes;
    },
    updateValues(values) {
      this.form.date.startDate = moment(values.startDate).format("YYYY-MM-DD");
      this.form.date.endDate = moment(values.endDate).format("YYYY-MM-DD");
    },
    getAllCompanies(search) {
      companies.getCompanyCombo(search).then((res) => {
        this.controls.companies = res.body.data;
        this.controls.companies.unshift({
          idCompany: null,
          companyName: "ALL",
        });
      });
    },
  },
  computed: {
    ...mapGetters(["getPrevious"]),
  },
};
</script>

<style>
.tables-inside {
  padding-top: 5px;
}

.dashboard .v-toolbar__content {
  height: 30px !important;
}

.dashboard-info {
  padding-top: 0;
  padding-bottom: 0;
}

.dashboard-container {
  top: -30px;
  position: relative;
}

.door {
  padding-top: 0;
  margin-top: 0;
}

.door .container {
  margin: 0;
  padding: 0;
}

.door .text {
  font-size: 1.2em;
  font-weight: bolder !important;
}

.table-title {
  padding: 5px;
}

table.v-table tbody td,
table.v-table tbody th {
  height: 30px;
}

table.v-table thead tr {
  height: 36px;
}

.v-datatable__actions__range-controls {
  width: 230px !important;
}

.v-datatable__actions__select {
  width: 140px !important;
}

.v-datatable__actions__select .v-select {
  margin-left: 10px !important;
}

.v-datatable__actions__pagination {
  margin-right: 0px !important;
}

.door-forklift {
  font-size: 0.8em;
}

.calendars {
  width: 710px;
}
</style>
