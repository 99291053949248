import Vue from "../../main.js";

const serviceUrl = "inbound";
const create = `${serviceUrl}/`;
const createPO = `${serviceUrl}/po`;
const closePO = `${serviceUrl}/close`;
const pending = `${serviceUrl}/pending`;
const historic = `${serviceUrl}/historic`;
const dashboardPendingPaged = `${serviceUrl}/dashboardPendingPaged`;
const dashboardDonePaged = `${serviceUrl}/dashboardDonePaged`;
const historicPaged = `${serviceUrl}/historicPaged`;
const done = `${serviceUrl}/done`;
const get = `${serviceUrl}/data`;
const download = `${serviceUrl}/download`;
const search = `${serviceUrl}/search`;
const qaData = `${serviceUrl}/qa/data`;
const qa = `${serviceUrl}/qa`;
const prefill = `${serviceUrl}/prefill`;
const products = `${serviceUrl}/products`;
const addProducts = `${serviceUrl}/products/add`;
const removeProducts = `${serviceUrl}/products/remove`;
const update = `${serviceUrl}`;
const qaHistoric = `${serviceUrl}/qa/historic`;
const downloadqa = `${serviceUrl}/qa/download`;
const finishqa = `${serviceUrl}/qa/finish`;
const reactive = `${serviceUrl}/reactive`;
const remove = `${serviceUrl}/remove`;
const calculationClosePaged = `${serviceUrl}/calculationClosePaged`;
const calculationCloseExport = `${serviceUrl}/calculationClose/export`;
const getClose = `${serviceUrl}/getclose`;
const hold = `${serviceUrl}/hold`;
const saveDashboardInOut = `${serviceUrl}/dashboard/inOut`;
const updateClose = `${serviceUrl}/close/update`;
const historicExport = `${serviceUrl}/historic/export`;

/**
 * Export
 */
export default {
  async create(data) {
    return Vue.http.post(create, data, {
    });
  },
  async createPO(data) {
    return Vue.http.post(createPO, data, {
    });
  },
  async dashboardPendingPaged(data) {
    return Vue.http.post(dashboardPendingPaged, data, {});
  },
  async dashboardDonePaged(data) {
    return Vue.http.post(dashboardDonePaged, data, {});
  },
  async historicPaged(data) {
    return Vue.http.post(historicPaged, data, {});
  },
  async getHistoric(search) {
    return Vue.http.post(
      historic,
      { search }
    );
  },
  async getSearch(text) {
    return Vue.http.get(search, {
      params: {
        text: text
      }
    });
  },
  async getPending() {
    return Vue.http.post(pending);
  },
  async getDone() {
    return Vue.http.post(done);
  },
  async getQA() {
    return Vue.http.post(qa);
  },
  async get(id) {
    return Vue.http.post(
      get,
      { id: id }
    );
  },
  async remove(id) {
    return Vue.http.post(
      remove,
      { id: id }
    );
  },
  async getPrefill(id) {
    return Vue.http.get(prefill, {
      params: {
        text: id
      }
    });
  },
  async download(id) {
    return Vue.http.post(
      download,
      { id: id },
      {
        responseType: "blob"
      }
    );
  },
  async update(data) {
    return Vue.http.patch(update, data, {
    });
  },
  async close(data) {
    return Vue.http.put(closePO, data, {
    });
  },
  async getProducts(id) {
    return Vue.http.post(
      products,
      {
        id: id
      }
    );
  },
  async getProductQA() {
    return Vue.http.post(
      get,
      { id: id }
    );
  },
  async addProduct(data) {
    return Vue.http.post(addProducts, data, {
      emulateJSON: true
    });
  },
  async removeProduct(data) {
    return Vue.http.post(removeProducts, data, {
      emulateJSON: true
    });
  },
  async getQAInformation(inboundCommodityID) {
    return Vue.http.post(
      qaData,
      {
        id: inboundCommodityID
      }
    );
  },
  async saveQAInformation(data) {
    return Vue.http.patch(qaData, data, {
    });
  },
  async getQAHistoric(search) {
    return Vue.http.post(
      qaHistoric,
      { search }
    );
  },
  async downloadqa(id) {
    return Vue.http.post(
      downloadqa,
      { id: id },
      {
        responseType: "blob"
      }
    );
  },
  async finishQA(inboundCommodityID) {
    return Vue.http.post(
      finishqa,
      {
        id: inboundCommodityID
      }
    );
  },
  async reactive(id) {
    return Vue.http.post(
      reactive,
      { id: id }
    );
  },
  async calculationClosePaged(data) {
    return Vue.http.post(calculationClosePaged, data, {});
  },
  async calculationCloseExport(data) {
    return Vue.http.post(calculationCloseExport, data,
      {
        responseType: "blob"
      }
    );
  },
  async getClose(id) {
    return Vue.http.post(getClose,
      {
        id: id
      }
    );
  },
  async hold(data) {
    return Vue.http.post(hold, data);
  },
  async saveDashboardInOut(data) {
    return Vue.http.post(saveDashboardInOut, data);
  },
  async updateClose(data) {
    return Vue.http.post(updateClose, data);
  },
  async historicExport(data) {
    return Vue.http.post(historicExport, data,
      {
        responseType: "blob"
      }
    );
  },
};
