import Vue from "../../main.js";
import { apiUrl } from '@/app.config';

const serviceUrl = apiUrl + "/companies";
const get = `${serviceUrl}/`;
const getAll = `${serviceUrl}/all`;
const getAllCompaniesPaged = `${serviceUrl}/getAllCompaniesPaged`;
const getById = `${serviceUrl}/getById`;
const saveEdit = `${serviceUrl}/saveEdit`;
const getCompanyCombo = `${serviceUrl}/getCompanyCombo`;
const create = `${serviceUrl}/create`;
const update = `${serviceUrl}/update`;

/**
 * Export
 */
export default {
  async create(data) {
    return Vue.http.post(create, data, {
      emulateJSON: true
    });
  },
  async update(data) {
    return Vue.http.patch(update, data, {
      emulateJSON: true
    });
  },
  async getAll(data) {
      return Vue.http.post(getAll, data, { });
    },
    async getAllCompaniesPaged(data) {
        return Vue.http.post(getAllCompaniesPaged, data, { });
    },
    async getById(idCompany) {
        return Vue.http.get(getById, { params: { idCompany: idCompany } });
    },
    async saveEdit(data) {
        return Vue.http.post(saveEdit, data, { });
    },
    async getCompanyCombo(data) {
        return Vue.http.get(getCompanyCombo, {
            params: {
                search: data
            }
        });
    },

  async get(id) {
    return Vue.http.post(
      get,
      { id: id },
      {
        emulateJSON: true
      }
    );
  }
};
